
import RefundModeTable from '../components/RefundModeTable.vue';
import VoidModeTable from '../components/VoidModeTable.vue';
import axios from 'axios';
import router from '@/router';
import { PaymentType } from '@/enums/PaymentType';
import { TransactionType } from '@/enums/TransactionType';
import Transaction from '@/Interfaces/Transaction';
import { DiscountType } from '@/enums/DiscountType';
import { ItemType } from '@/enums/ItemType';
import VoidService from '@/services/VoidService';
import RoleService from '../services/RoleService';
import LocalStorageService from '../services/LocalStorageService';

import html2pdf from 'html2pdf.js';
import OrderService from '@/services/OrderService';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'OrderDetails',
  components: {
    VoidModeTable,
    RefundModeTable,
  },
  data() {
    const data = {
      title: 'success',
      type: 'success',
      message: 'Email sent successfully!',
      showConfirm: false,
      voidService: undefined,
      roleService: undefined,
      localStorageService: undefined,
      orderService: undefined,

      statusId: null,
      orderId: null,
      transactions: [],
      discounts: [],
      sendToAddresses: [],
      isRefundMode: false,
      isVoidMode: false,
      transactionType: undefined,
      itemType: undefined,
      isRefund: false,
      isVoid: false,
      hasRelatedOrderObject: false,
      cc: undefined,
      showUserForbiddenAlert: false,
      loading: false,
      isSuperadminLoc: false,

      payload: undefined,
      order: undefined,

      creditCard: 'Credit Card',
      cash: 'Cash',
      emailSendDisabled: true,
      email: '',
      verifyBanner: true,
      emailSuccess: false,
      voidButtonDisabled: false,
      confirmVoidVisible: false,
      locationId: undefined,

      businessFields: {
        'createdBy.email': { label: 'Created By' },
        id: { label: 'Order ID' },
        transactionType: { label: 'Type' },
      },

      creditCardFields: {
        'createdBy.email': { label: 'Created By' },
        id: { label: 'Order ID' },
      },

      productFields: undefined,
      discountDetailFields: undefined,
      grossTotalFields: undefined,
      totalDetailFields: undefined,
      paymentDetailsCreditCardFields: undefined,
      paymentDetailsCreditCardFieldsList: undefined,
      paymentDetailsCashFields: {
        paymentType: {
          key: 'paymentType',
          tdClass: 'order-details-payment-type',
        },
        locationId: {
          key: 'Location ID',
          tdClass: 'order-details-payment-locationId',
        },
        customerName: {
          key: 'customer',
          tdClass: 'order-details-payment-customerName',
        },
      },
      paymentDetailsRefundFields: {
        locationId: {
          key: 'Location ID',
          tdClass: 'order-details-payment-locationId',
        },
        customerName: {
          key: 'customer',
          tdClass: 'order-details-payment-customerName',
        },
      },
    };
    return data;
  },
  created() {
    this.voidService = new VoidService();
    this.roleService = new RoleService(new LocalStorageService());
    this.localStorageService = new LocalStorageService();
    this.orderService = new OrderService();
    this.transactionType = TransactionType;
    this.itemType = ItemType;
    this.order = {
      id: '',
      subtotal: 0,
      tip: 0,
      tax: 0,
      grossSale: 0,
      total: 0,
      variantTotalWholesalePrice: 0,
      profit: 0,
      isPaid: true,
      variantSnapshots: [],
      transactions: [],
      relatedOrder: '',
      customer: {
        id: '',
        first_name: '',
        last_name: '',
        account_number: '',
        email: '',
      },
      paymentType: PaymentType.None,
      transactionType: TransactionType.None,
      location: {
        id: '',
        name: '',
        taxLocations: [],
        email: '',
      },
      discounts: [
        {
          id: '',
          value: 0,
          friendlyDisplay: '',
          name: '',
          orderId: '',
          discountType: DiscountType.Percentage,
        },
      ],
      discuntAmount: 0,
    };
    this.payload = {
      orderId: this.orderId,
      cc: this.cc,
      sendToAddresses: this.sendToAddresses,
    };
    this.productFields = {
      variantSku: {
        label: 'SKU',
      },
      itemName: {
        label: 'Item Name',
      },
      itemManufacturer: {
        label: 'Manufacturer',
      },
      variantName: {
        label: 'Variant Name',
      },
      variantPrice: {
        label: 'Price',
        formatter: (price: any) => {
          return this!.$options!.filters!.currency(price);
        },
        tdClass: (value: any) => {
          if (value < 0) return 'negative';
        },
      },
      variantQuantity: {
        label: 'Quantity',
      },
      variantUpc: {
        label: 'UPC',
      },
      itemType: { label: 'Type', key: 'itemType' },
    };
    this.discountDetailFields = {
      discountAmount: {
        label: 'Discount',
        tdClass: (value: any) => {
          if (value < 0) return 'negative';
        },
        formatter: (price: any) => {
          return this!.$options!.filters!.currency(price);
        },
      },
      nameValue: {
        label: '',
        tdClass: 'create-order-discount-lbl',
      },
    };
    this.grossTotalFields = {
      grossSale: {
        label: 'Subtotal prior to discount',
        tdClass: (value: any) => {
          if (value < 0) return 'negative';
        },
        formatter: (price: any) => {
          return this!.$options!.filters!.currency(price);
        },
      },
      nameValue: {
        label: '',
        tdClass: 'create-order-discount-lbl',
      },
    };
    this.totalDetailFields = {
      subtotal: {
        tdClass: (value: any) => {
          if (value < 0) return 'create-order-subtotal-lbl negative';
          else return 'create-order-subtotal-lbl';
        },
        formatter: (price: any) => {
          return this!.$options!.filters!.currency(price);
        },
      },
      tax: {
        tdClass: (value: any) => {
          if (value < 0) return 'create-order-tax-lbl negative';
          else return 'create-order-tax-lbl';
        },
        formatter: (price: any) => {
          return this!.$options!.filters!.currency(price);
        },
      },
      tip: {
        tdClass: (value: any) => {
          if (value < 0) return 'create-order-total-lbl negative';
          else return 'create-order-total-lbl';
        },
        formatter: (price: any) => {
          return this!.$options!.filters!.currency(price);
        },
      },
      total: {
        tdClass: (value: any) => {
          if (value < 0) return 'create-order-total-lbl negative';
          else return 'create-order-total-lbl';
        },
        formatter: (price: any) => {
          return this!.$options!.filters!.currency(price);
        },
      },
    };
    this.paymentDetailsCreditCardFields = {
      paymentType: {
        key: 'paymentType',
        tdClass: 'order-details-payment-type',
      },
      customerName: {
        key: 'customer',
        tdClass: 'order-details-payment-customerName',
      },
      statusDescription: {
        label: 'Status',
        tdClass: 'order-details-status-code',
        formatter: (value: any) => {
          return this!.$options!.filters!.replaceEmpty(value);
        },
      },
      authCode: {
        tdClass: 'order-details-payment-authCode',
        formatter: (value: any) => {
          return this!.$options!.filters!.replaceEmpty(value);
        },
      },
      locationId: {
        key: 'Location ID',
        tdClass: 'order-details-payment-locationId',
      },
    };
    this.paymentDetailsCreditCardFieldsList = {
      fortisTransactionId: {
        label: 'Transaction ID',
        formatter: (value: any) => {
          return this!.$options!.filters!.replaceEmpty(value);
        },
      },
      accountType: {
        tdClass: 'order-details-payment-accountType',
        formatter: (value: any) => {
          return this!.$options!.filters!.replaceEmpty(value);
        },
      },
      entryModeDescription: {
        label: 'Entry Mode',
        tdClass: 'order-details-payment-entry-mode',
        formatter: (value: any) => {
          return this!.$options!.filters!.replaceEmpty(value);
        },
      },
      lastFour: {
        label: 'Last Four Digits of Card',
        tdClass: 'order-details-payment-lastFour',
        formatter: (value: any) => {
          return this!.$options!.filters!.replaceEmpty(value);
        },
      },
    };
    this.emvReceiptDataFields = {
      TVR: {
        label: 'TVR',
        formatter: (value: any) => {
          return this!.$options!.filters!.replaceEmpty(value);
        },
      },
      AID: {
        label: 'AID',
        formatter: (value: any) => {
          return this!.$options!.filters!.replaceEmpty(value);
        },
      },
      TSI: {
        label: 'TSI',
        formatter: (value: any) => {
          return this!.$options!.filters!.replaceEmpty(value);
        },
      },
      APPLAB: {
        label: 'APPLAB',
        formatter: (value: any) => {
          return this!.$options!.filters!.replaceEmpty(value);
        },
      },
      CVM: {
        label: 'CVM',
        formatter: (value: any) => {
          return this!.$options!.filters!.replaceEmpty(value);
        },
      },
      PAN: {
        label: 'PAN',
        formatter: (value: any) => {
          return this!.$options!.filters!.replaceEmpty(value);
        },
      },
      MID: {
        label: 'MID',
        formatter: (value: any) => {
          return this!.$options!.filters!.replaceEmpty(value);
        },
      },
      IAD: {
        label: 'IAD',
        formatter: (value: any) => {
          return this!.$options!.filters!.replaceEmpty(value);
        },
      },
      ARC: {
        label: 'ARC',
        formatter: (value: any) => {
          return this!.$options!.filters!.replaceEmpty(value);
        },
      },
      CURR: {
        label: 'CURR',
        formatter: (value: any) => {
          return this!.$options!.filters!.replaceEmpty(value);
        },
      },
      MODE: {
        label: 'MODE',
        formatter: (value: any) => {
          return this!.$options!.filters!.replaceEmpty(value);
        },
      },
    };
  },
  async mounted() {
    this.locationId = this.$route.query.locationId.toString();
    this.roleService.getRole();
    const locationOfSuperadmin = this.localStorageService.getItem('locations');
    await this.getOrder(this.$route.params.id.toString(), this.locationId);
    await this.checkStatus();
    this.isSuperadminLoc = false;
    if (this.order) {
      this.order.transactions.forEach((transaction) => {
        const correspondingOrderPayment = this.order.orderPayment.find(
          (orderPayment) => orderPayment.transactionApiId === transaction.id,
        );
        if (correspondingOrderPayment) {
          transaction.depositAccountTitle =
            correspondingOrderPayment.depositAccountTitle;
        }
      });
      for (let index = 0; index < this.order.variantSnapshots.length; index++) {
        if (
          this.order.variantSnapshots[index].variantQuantity ===
          this.order.variantSnapshots[index].refundedQuantity
        ) {
          this.order.variantSnapshots[index].isVoided = true;
        }
      }
      if (
        locationOfSuperadmin &&
        locationOfSuperadmin.slice(2, -2) !== this.order.location.id &&
        this.roleService.isSuperadmin
      ) {
        this.isSuperadminLoc = true;
      }
    }
  },
  methods: {
    checkTransactionsIsRefundable() {
      this.order.transactions.forEach((transaction) => {
        if (transaction.isRefundable) {
          return true;
        }
      });
    },

    async getOrder(orderId: string, locationId: string) {
      this.loading = true;
      //-----store implementation----//
      const res = await this.orderService.getOrderDetails(orderId, locationId);
      if (res.status === 200) {
        this.loading = false;
        this.order = res.data.order;
        if (res.data.order.customer && res.data.order.customer.email) {
          this.email = res.data.order.customer.email;
          this.emailSendDisabled = false;
        }
      }
    },

    displayNegativeCurrency(cellValue: number | string) {
      return {
        negative: Number(cellValue) < 0,
      };
    },

    isVoidable() {
      const value =
        (this.isCreditCardOrder || this.isACHOrder) &&
        this.order.transactions.length > 0 &&
        this.order.transactions.some((transaction) => transaction.isVoidable);
      return value;
    },

    handleChangeVoidMode() {
      this.isVoidMode = !this.isVoidMode;
    },

    isVoidButtonVisible() {
      return (
        !this.isVoidMode && this.order.transactionType !== TransactionType.Void
      );
    },

    isRefundButtonVisible() {
      return (
        !this.isRefundMode &&
        !this.isVoidMode &&
        this.order.transactionType !== TransactionType.Refund &&
        this.orderHasRefundableSnapshots() &&
        ((this.order.transactions.length > 0 &&
          this.order.transactions.some(
            (transaction) => transaction.isRefundable,
          )) ||
          this.order.paymentType === PaymentType.Cash)
      );
    },

    orderHasRefundableSnapshots() {
      const refundableSnapshots = this.order.variantSnapshots.filter(
        (vs) => !vs.isRefunded,
      );

      return refundableSnapshots.length > 0;
    },

    handleChangeRefundMode() {
      this.isRefundMode = !this.isRefundMode;
    },

    handleVoidTransaction() {
      this.voidButtonDisabled = true;
      this.confirmVoidVisible = true;
    },

    cancelVoid() {
      this.confirmVoidVisible = false;
      this.voidButtonDisabled = false;
    },

    getPriceValue(value: any) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return value === null || Number.isNaN(value)
        ? '-'
        : formatter.format(value);
    },

    async executeVoid(transaction: Transaction) {
      try {
        const response = await axios.put(
          `${process.env.VUE_APP_BASE_URL}/transaction/${transaction.id}/void`,
        );
        const voidedTransaction = response.data.transaction;

        const voidOrder = this.voidService.createVoidOrder(this.order);
        const orderResponse = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/order`,
          voidOrder,
        );

        router.push({ path: `/orders/${orderResponse.data.order.order.id}` });
        const location = this.$route.fullPath;
        this.$router.replace('/');
        this.$nextTick(() => this.$router.replace(location));
      } catch (error) {
        this.showUserForbiddenAlert = true;
      }
    },

    async downloadPdf() {
      this.loading = true;
      this.payload.orderId = this.order.id;
      axios
        .post(
          `${process.env.VUE_APP_BASE_URL}/email?template=orderReceiptTemplate`,
          this.payload,
          {
            params: {
              locationId: this.locationId,
            },
          },
        )
        .then(async (res) => {
          if (res.status === 200) {
            const pdfHtml = res.data.result.bodyHtml;
            await html2pdf()
              .set({
                margin: [0.2, 0, 0.1, 0],
                mode: 'legacy',
                pagebreak: { avoid: 'tr' },
                filename: this.order.id + '.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 1 },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'p' },
              })
              .from(pdfHtml)
              .output('datauristring')
              .then(async (res: any) => {
                const blob: any = await (await fetch(res)).blob();
                const fileURL = URL.createObjectURL(blob);
                window.open(fileURL);
              });
            this.loading = false;
          }
        });
    },

    sendEmail() {
      this.showConfirm = false;
      this.loading = true;
      this.emailSendDisabled = true;
      this.payload.orderId = this.order.id;
      this.payload.cc = this.order.location.email;
      this.payload.sendToAddresses = this.email
        .split(';')
        .map((email) => String(email).trim());
      axios
        .post(
          `${process.env.VUE_APP_BASE_URL}/email?template=orderReceipt`,
          this.payload,
          {
            params: {
              locationId: this.locationId,
            },
          },
        )
        .then((res) => {
          if (res.status === 200) {
            this.title = 'Success';
            this.type = 'success';
            this.message = 'Email sent successfully!';
            this.verifyEmail();
            this.showConfirm = true;
            this.emailSuccess = true;
            this.emailSendDisabled = false;
            this.loading = false;
          } else {
            this.title = 'Error';
            this.type = 'error';
            this.message = 'Email failed to send';
            this.showConfirm = true;
            this.verifyEmail();
            this.emailSuccess = true;
            this.emailSendDisabled = false;
            this.loading = false;
          }
        })
        .catch(() => {
          this.title = 'Error';
          this.type = 'error';
          this.message = 'Email failed to send';
          this.showConfirm = true;
          this.verifyEmail();
          this.emailSuccess = true;
          this.emailSendDisabled = false;
          this.loading = false;
        });

      this.sendToAddresses = [];
      this.verifyBanner = false;
    },

    validateEmail(email: string) {
      //eslint-disable-next-line
      var re =
        /^(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-\./\s/]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/;
      return re.test(String(email).toLowerCase());
    },

    validate() {
      if (this.validateEmail(this.email)) {
        this.emailSendDisabled = false;
        this.emailSuccess = false;
      } else {
        this.emailSendDisabled = true;
      }
    },

    verifyEmail() {
      this.emailSuccess = true;
    },

    checkStatus() {
      if (this.order.transactionType === this.transactionType.Refund) {
        this.isRefund = true;
      } else if (this.order.transactionType === this.transactionType.Void) {
        this.isVoid = true;
      } else if (this.order.childOrders) {
        this.hasRelatedOrderObject = true;
      } else {
        this.isVoid = false;
        this.isRefund = false;
      }
    },

    goToRelatedOrder(id: string) {
      this.$nextTick(() => {
        window.location.href = window.location.href.replace(
          window.location.hash,
          '#/orders/' + id + '?locationId=' + this.locationId,
        );
        window.location.reload();
      });
    },
  },
  computed: {
    hasComplimentaryItems() {
      return this.order.variantSnapshots.some(
        (item) =>
          item.isCompItem ||
          item.complementaryReason ||
          item.complementaryReasonId,
      );
    },
    isCreditCardOrder() {
      return this.order.paymentType === PaymentType.CreditCard;
    },

    isACHOrder() {
      return this.order.paymentType === PaymentType.Ach;
    },

    hasCustomer() {
      return this.order.customer !== undefined && this.order.customer !== null;
    },

    hasEMVData() {
      return (
        this.order.transactions.length > 0 &&
        this.order.transactions[0].emvData !== null
      );
    },

    hasDiscount() {
      return (
        this.order.discounts !== undefined && this.order.discounts !== null
      );
    },
  },
});

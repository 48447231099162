
import { Options, Vue } from 'vue-class-component';
import VariantSnapshot from '../Interfaces/VariantSnapshot';
import { CreateOrderRequest } from '@/contracts/CreateOrderRequest';
import { PaymentType } from '@/enums/PaymentType';
import { TransactionType } from '@/enums/TransactionType';
import axios, { AxiosResponse } from 'axios';
import FortisTransaction from '../Interfaces/FortisTransaction';
import {v4} from 'uuid';
import {formatPrice} from '@/helper/products';

@Options({
  components: {},
  props: ['order'],
})
export default class VoidModeTable extends Vue {
  itemsToRefund: VariantSnapshot[] = [];
  itemsToReturnToInventory: VariantSnapshot[] = [];
  confirmCashRefundVisible = false;
  showCreditCardRefundOptions = false;
  confirmCreditCardRefundVisible = false;
  isTotalRefundZero = false;
  orderCopy: any = null;
  payformOrderId = '';
  showUserForbiddenAlert = false;
  refundableAmount = 0;
  isRefundConfirmClicked = false;
  order: any = {};
  isAllServices = true;

  fields = {
    variantSku: {label: 'SKU'},
    itemManufacturer: {label: 'Manufacturer'},
    itemName: {label: 'Item Name'},
    variantName: {label: 'Variant Name'},
    variantPrice: {
      label: 'Price',
      formatter: (price: any) => {
        return this!.$options!.filters!.currency(price);
      },
    },
    variantQuantity: {label: 'Quantity'},
    variantTotal: {
      label: 'Total Amount',
      formatter: (price: any) => {
        return this!.$options!.filters!.currency(price);
      },
    },
    refund: {
      label: 'Void?',
    },
    inventoryReturn: {
      label: 'Return to Inventory?',
    },
    refundableQuantity: {
      label: 'Voidable Quantity',
    },
    refundableAmount: {
      label: 'Voidable Amount',
    },
  };

  setup(context: any, props: any) {
    this.order = props.order;
  }

  mounted() {
    this.orderCopy = this.order;
    // Check if all variants in the item are serives so we can remove column 'Return to Inventory'
    this.orderCopy.variantSnapshots.forEach(async (variant) => {
      if (this.orderCopy.orderPayment.length > 1) {
        this.handleToggleRefundItem(variant);
        this.handleToggleReturnToInventory(variant, true);
      }
      if (variant.itemType !== 2) {
        this.isAllServices = false;
      }
    });
  }

  handleToggleRefundItem(event: VariantSnapshot) {
    if (event.refundedQuantity) {
      event.refundbleQuantity = event.variantQuantity - event.refundedQuantity;
    } else {
      event.refundbleQuantity = event.variantQuantity;
    }
    event.refundedQuantity = event.refundbleQuantity;
    this.updateQuantity(event, '');

    if (this.itemsToRefund.includes(event)) {
      this.itemsToRefund = this.itemsToRefund.filter(
          (snapshot) => snapshot !== event,
      );
      this.itemsToReturnToInventory = this.itemsToReturnToInventory.filter(
          (snapshot) => snapshot !== event,
      );
    } else {
      this.itemsToRefund.push(event);
    }
    this.calculateIsTotalRefundZero();
  }

  handleToggleReturnToInventory(event: VariantSnapshot, isSplit?: boolean) {
    if (isSplit) {
      this.itemsToReturnToInventory.push(event);
    } else if (this.itemsToReturnToInventory.includes(event)) {
      this.itemsToReturnToInventory = this.itemsToReturnToInventory.filter(
          (snapshot) => snapshot !== event,
      );
    } else {
      this.itemsToReturnToInventory.push(event);
    }
  }

  async initiateVoid() {
    this.isRefundConfirmClicked = true;
    const order =
        this.order.paymentType == 3
            ? this.createRefundOrder(PaymentType.Ach)
            : this.createRefundOrder(PaymentType.CreditCard);

    let apiResponse: AxiosResponse<any, any>;
    try {
      apiResponse = await this.dispatchCashRefund(order);
      if (apiResponse.status === 200) {

        await Promise.all(this.order.transactions.map(async (transaction: any) => {
          if (transaction.accountType === 'cash') {
            const fortisTransactionCash: FortisTransaction = {
              transaction_api_id: transaction.id,
              transaction_amount: transaction.transactionAmount,
              location_id: transaction.locationId,
            };

            try {
              await axios.post(
                  `${process.env.VUE_APP_BASE_URL}/fortis/transaction?refund=cash`,
                  fortisTransactionCash,
              );
            } catch (err) {
              apiResponse = err.response;
              this.showUserForbiddenAlert = true;
            }
          }

          if (transaction.isRefundable) {
            return;
          }
          const fortisTransaction: FortisTransaction = {
            transaction_api_id: transaction.id,
            transaction_amount:
                this.order.orderPayment.length > 0
                    ? transaction.transactionAmount
                    : transaction.transactionAmount - this.calculateTotalRefund(),
            location_id: transaction.locationId,
            void_type:
                this.order.orderPayment.length > 0
                    ? 'Void'
                    : transaction.transactionAmount > this.calculateTotalRefund()
                        ? 'PartialVoid'
                        : 'Void',
          };
          await axios.put(
              `${process.env.VUE_APP_BASE_URL}/fortis/transaction/${transaction.id}/void`,
              fortisTransaction,
          );
        }) );
        await this.$nextTick(() => {
          this.$router.push(`/orders`);
        });
      }
    } catch (err) {
      apiResponse = err.response;
      this.showUserForbiddenAlert = true;
    }
  }


  createRefundOrder(paymentType: PaymentType) {
    const order = new CreateOrderRequest();
    order.paymentType = paymentType;
    order.transactionType = TransactionType.Void;
    order.customer = this.order.customer;
    order.location = this.order.location;
    order.relatedOrder = this.order.id;
    order.id = v4();

    if (this.order.discounts && this.order.discounts.length > 0) {
      this.order.discounts[0].id = undefined;
      order.discounts = this.order.discounts;
    }
    order.variantSnapshots = this.createRefundableSnapshots();
    return order;
  }

  async dispatchCashRefund(order: CreateOrderRequest) {
    return await axios.post(`${process.env.VUE_APP_BASE_URL}/order`, order);
  }

  createRefundableSnapshots(): VariantSnapshot[] {
    this.itemsToRefund.forEach((item) => {
      item.returnToInventory = !!this.itemsToReturnToInventory.includes(item);
    });
    return this.itemsToRefund;
  }

  cancelVoid() {
    this.confirmCashRefundVisible = false;
  }

  handleConfirmVoid() {
    this.confirmCashRefundVisible = true;
  }

  handleChangeVoidMode() {
    for (let i = 0; i < this.order.variantSnapshots.length; i++) {
      this.order.variantSnapshots[i].refundedQuantity =
        this.orderCopy.variantSnapshots[i].refundedQuantity;
    }
    this.$emit('handle-change-void-mode');
  }

  calculateTotalRefund() {
    const refundTotals = this.itemsToRefund.map(
      (item) => +item.refundableAmount,
    );
    if (refundTotals.length > 0) {
      const totalRefound = refundTotals.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
      );
      return formatPrice(totalRefound);
    } else {
      return 0;
    }
  }

  calculateIsTotalRefundZero() {
    return this.calculateTotalRefund() <= 0;
  }

  get isRefundItemsDisabled() {
    return (
      this.itemsToRefund.length < 1 ||
      this.confirmCashRefundVisible === true ||
      this.showCreditCardRefundOptions === true ||
      this.isTotalRefundZero === true
    );
  }

  get isCancelRefundDisabled() {
    return (
      this.confirmCashRefundVisible === true ||
      this.showCreditCardRefundOptions === true
    );
  }

  public updateQuantity(variant: VariantSnapshot, type: string) {
    if (variant.refundbleQuantity === undefined) {
      variant.refundbleQuantity =
        variant.variantQuantity - variant.refundedQuantity;
    }

    if (type === 'plus') {
      if (
        variant.refundbleQuantity > variant.refundedQuantity &&
        variant.refundbleQuantity > 1
      ) {
        variant.refundedQuantity++;
      }
    } else if (type === 'minus') {
      if (variant.refundedQuantity > 1) {
        variant.refundedQuantity--;
      }
    }

    let perQuantitydiscount = variant.itemDiscount / variant.variantQuantity;
    let singleVariantTax = variant.variantTaxedAmount / variant.variantQuantity;
    let singleVariantTip = Number(variant.tip) / variant.variantQuantity;
    let totalAmt =
      variant.refundedQuantity * variant.variantPrice +
      variant.refundedQuantity * singleVariantTax +
      variant.refundedQuantity * singleVariantTip;
    variant.refundableAmount =
      totalAmt - perQuantitydiscount * variant.refundedQuantity;
    variant.refundableAmount.toFixed(2);
    this.$forceUpdate();
  }
}

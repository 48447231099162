
import VariantSnapshot from '../Interfaces/VariantSnapshot';
import { CreateOrderRequest } from '../contracts/CreateOrderRequest';
import { PaymentType } from '../enums/PaymentType';
import { TransactionType } from '../enums/TransactionType';
import axios, { AxiosResponse } from 'axios';
import FortisTransaction from '../Interfaces/FortisTransaction';
import Transaction from '../Interfaces/Transaction';
import { v4 } from 'uuid';
import { defineComponent } from 'vue';
import Order from '@/Interfaces/Order';

interface ComponentData {
  activateNotification: boolean;
  typeNotification: 'success' | 'error';
  titleNotification: string;
  messageNotification: string;

  itemsToRefund: VariantSnapshot[];
  itemsToReturnToInventory: VariantSnapshot[];
  confirmCashRefundVisible: boolean;
  showCreditCardRefundOptions: boolean;
  confirmCreditCardRefundVisible: boolean;
  isTotalRefundZero: boolean;
  orderCopy: Order;

  payformOrderId: string;
  newTransaction: Transaction;
  showUserForbiddenAlert: boolean;
  refundableAmount: number;
  isRefundConfirmClicked: boolean;
  order: any;
  isAllServices: boolean;

  fields: {
    variantSku: { label: string };
    itemManufacturer: { label: string };
    itemName: { label: string };
    variantName: { label: string };
    variantPrice: {
      label: string;
      formatter: (price: any) => void;
    };
    variantQuantity: { label: string };
    variantTotal: {
      label: string;
      formatter: (price: any) => void;
    };
    refund: {
      label: string;
    };
    inventoryReturn: {
      label: string;
    };
    refundableQuantity: {
      label: string;
    };
    refundableAmount: {
      label: string;
    };
  };
}

export default defineComponent({
  name: 'RefundModeTable',
  props: ['order', 'transactionOrder'],
  emits: ['handle-change-refund-mode'],
  data() {
    const data: ComponentData = {
      activateNotification: false,
      typeNotification: 'success',
      titleNotification: '',
      messageNotification: '',

      itemsToRefund: [],
      itemsToReturnToInventory: [],
      confirmCashRefundVisible: false,
      showCreditCardRefundOptions: false,
      confirmCreditCardRefundVisible: false,
      isTotalRefundZero: false,
      orderCopy: null,

      payformOrderId: '',
      newTransaction: {},
      showUserForbiddenAlert: false,
      refundableAmount: 0,
      isRefundConfirmClicked: false,
      order: {},
      isAllServices: true,

      fields: undefined,
    };
    return data;
  },
  created() {
    this.fields = {
      variantSku: { label: 'SKU' },
      itemManufacturer: { label: 'Manufacturer' },
      itemName: { label: 'Item Name' },
      variantName: { label: 'Variant Name' },
      variantPrice: {
        label: 'Price',
        formatter: (price: any) => {
          return this!.$options!.filters!.currency(price);
        },
      },
      variantQuantity: { label: 'Quantity' },
      variantTotal: {
        label: 'Total Amount',
        formatter: (price: any) => {
          return this!.$options!.filters!.currency(price);
        },
      },
      refund: {
        label: 'Refund?',
      },
      inventoryReturn: {
        label: 'Return to Inventory?',
      },
      refundableQuantity: {
        label: 'Refundable Quantity',
      },
      refundableAmount: {
        label: 'Refundable Amount',
      },
    };
  },
  mounted() {
    this.orderCopy = this.transactionOrder;
    // Check if all variants in the item are serives so we can remove column 'Return to Inventory'
    this.orderCopy.variantSnapshots.forEach(async (variant) => {
      if (this.orderCopy.orderPayment.length > 1) {
        await this.handleToggleRefundItem(variant);
        await this.handleToggleReturnToInventory(variant, true);
      }
      if (variant.itemType !== 2) {
        this.isAllServices = false;
      }
    });
  },
  methods: {
    handleToggleRefundItem(event: VariantSnapshot) {
      if (event.refundedQuantity) {
        event.refundbleQuantity =
          event.variantQuantity - event.refundedQuantity;
      } else {
        event.refundbleQuantity = event.variantQuantity;
      }
      event.refundedQuantity = event.refundbleQuantity;
      this.updateQuantity(event, '');

      if (this.itemsToRefund.includes(event)) {
        this.refundableAmount = 0;
        this.itemsToRefund = this.itemsToRefund.filter(
          (snapshot) => snapshot !== event,
        );
        this.itemsToReturnToInventory = this.itemsToReturnToInventory.filter(
          (snapshot) => snapshot !== event,
        );
      } else {
        this.itemsToRefund.push(event);
      }
      this.calculateIsTotalRefundZero();
    },

    handleToggleReturnToInventory(event: VariantSnapshot, isSplit?: boolean) {
      if (isSplit) {
        this.itemsToReturnToInventory.push(event);
      } else if (this.itemsToReturnToInventory.includes(event)) {
        this.itemsToReturnToInventory = this.itemsToReturnToInventory.filter(
          (snapshot) => snapshot !== event,
        );
      } else {
        this.itemsToReturnToInventory.push(event);
      }
    },

    async initiateCreditCardOrCashOrAchRefund() {
      this.isRefundConfirmClicked = true;
      let paymentLabel = '';

      if (this.transactionOrder.paymentType === PaymentType.Cash) {
        paymentLabel = 'cash';
      } else if (this.transactionOrder.paymentType === PaymentType.CreditCard) {
        paymentLabel = this.confirmCashRefundVisible ? 'cash-refund' : 'cc';

      } else if (this.transactionOrder.paymentType === PaymentType.Ach) {
        paymentLabel = this.confirmCashRefundVisible ? 'cash-refund' : 'ach';
      }

      const order = this.createRefundOrder(this.transactionOrder.paymentType);

      let orderResponse;
      try {
        orderResponse = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/order`,
          order,
        );
      } catch (err) {
        orderResponse = err.response;
      }
      if (orderResponse.status === 200) {
        this.transactionOrder.transactions.forEach(
          async (transaction: Transaction) => {
            const fortisTransaction: FortisTransaction = {
              transaction_api_id: transaction.id,
              transaction_amount: transaction.transactionAmount,
              location_id: transaction.locationId,
            };

            if (transaction.isRefundable === false || transaction.isVoided) {
              return;
            }

            let fortisTransactionResponse;
            paymentLabel =
              transaction.accountType === 'checking' ||
              transaction.accountType === 'savings'
                ? 'ach'
                : transaction.accountType === 'cash'
                ? 'cash'
                : 'cc';
            try {
              fortisTransactionResponse = await axios.post(
                `${process.env.VUE_APP_BASE_URL}/fortis/transaction?refund=${paymentLabel}`,
                fortisTransaction,
              );

              if (fortisTransactionResponse.status === 201) {
                order.accountType =
                  fortisTransactionResponse.data.transaction.accountType;
                await this.dispatchCreditCardRefundOrder(
                  order,
                  fortisTransactionResponse,
                  orderResponse,
                );
              }
            } catch (err) {
              this.isRefundConfirmClicked = false;
              fortisTransactionResponse = err.response;
              this.showUserForbiddenAlert = true;
            }
          },
        );
        this.isRefundConfirmClicked = false;
        this.activateNotification = true;
        this.typeNotification = 'success';
        this.titleNotification = 'Success';
        this.messageNotification = 'Refund Successful';
        this.$nextTick(() => {
          this.$router.push(`/orders`);
        });
      }
    },

    async dispatchCreditCardRefundOrder(
      order: CreateOrderRequest,
      fortisTransactionResponse: any,
      orderResponse: any,
    ) {
      order.id = orderResponse.data.order.order.id;
      const transaction: Transaction = {
        id: fortisTransactionResponse.data.transaction.fortisTransactionId,
        transaction_api_id: fortisTransactionResponse.data.transaction.id,
        order: orderResponse.data.order.order,
      };
      await this.dispatchCreditCardRefundInternalTransaction(transaction);
    },

    async dispatchCreditCardRefundInternalTransaction(
      transaction: Transaction,
    ) {
      let transactionResponse;
      try {
        transactionResponse = await axios.put(
          `${process.env.VUE_APP_BASE_URL}/transaction/${transaction.id}`,
          transaction,
        );
      } catch (err) {
        transactionResponse = err.response;
      }
    },

    async initiateCashRefund() {
      this.isRefundConfirmClicked = true;
      const order = this.createRefundOrder(PaymentType.Cash);
      let apiResponse: AxiosResponse;
      try {
        apiResponse = await this.dispatchCashRefund(order);
        if (apiResponse.status === 200) {
          this.transactionOrder.transactions.forEach(
            async (transaction: Transaction) => {
              const fortisTransaction: FortisTransaction = {
                transaction_api_id: transaction.id,
                transaction_amount: transaction.transactionAmount,
                location_id: transaction.locationId,
              };

              let fortisTransactionResponse;
              try {
                fortisTransactionResponse = await axios.post(
                  `${process.env.VUE_APP_BASE_URL}/fortis/transaction?refund=cash`,
                  fortisTransaction,
                );
              } catch (error) {
                this.isRefundConfirmClicked = false;
                apiResponse = error.response;
                this.showUserForbiddenAlert = true;
              }
            },
          );
          this.activateNotification = true;
          this.typeNotification = 'success';
          this.titleNotification = 'Success';
          this.messageNotification = 'Refund Successful';
          this.$nextTick(() => {
            this.$router.push(`/orders`);
          });
          this.isRefundConfirmClicked = false;
        }
      } catch (err) {
        apiResponse = err.response;
        this.showUserForbiddenAlert = true;
        this.isRefundConfirmClicked = false;
      }
    },

    createRefundOrder(paymentType: PaymentType) {
      const order = new CreateOrderRequest();
      order.paymentType = paymentType;
      order.transactionType = TransactionType.Refund;
      order.customer = this.transactionOrder.customer;
      order.location = this.transactionOrder.location;
      order.relatedOrder = this.transactionOrder.id;
      order.depositAccountId = this.transactionOrder.depositAccountId;
      order.depositAccountTitle = this.transactionOrder.depositAccountTitle;
      order.id = v4();

      if (
        this.transactionOrder.discounts &&
        this.transactionOrder.discounts.length > 0
      ) {
        this.transactionOrder.discounts[0].id = undefined;
        order.discounts = this.transactionOrder.discounts;
      }
      order.variantSnapshots = this.createRefundableSnapshots();
      return order;
    },

    async dispatchCashRefund(order: CreateOrderRequest) {
      return await axios.post(`${process.env.VUE_APP_BASE_URL}/order`, order);
    },

    createRefundableSnapshots(): VariantSnapshot[] {
      this.itemsToRefund.forEach((item) => {
        item.returnToInventory = !!this.itemsToReturnToInventory.includes(item);
      });
      return this.itemsToRefund;
    },

    cancelCashRefund() {
      this.confirmCashRefundVisible = false;
    },

    cancelCreditCardRefund() {
      this.confirmCreditCardRefundVisible = false;
    },

    handleShowCreditCardRefundOptions() {
      this.showCreditCardRefundOptions = !this.showCreditCardRefundOptions;
      this.confirmCashRefundVisible = false;
      this.confirmCreditCardRefundVisible = false;
    },

    handleConfirmRefund() {
      this.handleConfirmCashRefund();
    },

    handleConfirmCashRefund() {
      this.confirmCashRefundVisible = true;
      this.confirmCreditCardRefundVisible = false;
    },

    handleConfirmCreditCardRefund() {
      this.confirmCreditCardRefundVisible = true;
      this.confirmCashRefundVisible = false;
    },

    handleChangeRefundMode() {
      for (let i = 0; i < this.transactionOrder.variantSnapshots.length; i++) {
        // eslint-disable-next-line vue/no-mutating-props
        this.transactionOrder.variantSnapshots[i].refundedQuantity =
          this.orderCopy.variantSnapshots[i].refundedQuantity;
      }
      this.$emit('handle-change-refund-mode');
    },

    calculateTotalRefund() {
      const refundTotals = this.itemsToRefund.map((item) => +item.variantTotal);
      if (refundTotals.length > 0) {
        return refundTotals.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
        );
      } else {
        return 0;
      }
    },

    calculateIsTotalRefundZero() {
      return this.calculateTotalRefund() <= 0;
    },

    updateQuantity(variant: VariantSnapshot, type: string) {
      if (variant.refundbleQuantity === undefined) {
        variant.refundbleQuantity =
          variant.variantQuantity - variant.refundedQuantity;
      }

      if (type === 'plus') {
        if (
          variant.refundbleQuantity > variant.refundedQuantity &&
          variant.refundbleQuantity > 1
        ) {
          variant.refundedQuantity++;
        }
      } else if (type === 'minus') {
        if (variant.refundedQuantity > 1) {
          variant.refundedQuantity--;
        }
      }

      let totalAmt = 0;
      let singleVariantTax = 0;
      let perQuantitydiscount = 0;
      perQuantitydiscount = variant.itemDiscount / variant.variantQuantity;
      singleVariantTax = variant.variantTaxedAmount / variant.variantQuantity;
      totalAmt =
        variant.refundedQuantity * variant.variantPrice +
        variant.refundedQuantity * singleVariantTax;
      variant.refundableAmount =
        totalAmt - perQuantitydiscount * variant.refundedQuantity;
      variant.refundableAmount.toFixed(2);
      this.$forceUpdate();
    },
  },
  computed: {
    isRefundItemsDisabled() {
      return (
        this.itemsToRefund.length < 1 ||
        this.confirmCashRefundVisible === true ||
        this.showCreditCardRefundOptions === true ||
        this.isTotalRefundZero === true
      );
    },

    isCancelRefundDisabled() {
      return (
        this.confirmCashRefundVisible === true ||
        this.showCreditCardRefundOptions === true
      );
    },
  },
});

import axios from 'axios';
import VariantSnapshot from '@/Interfaces/VariantSnapshot';
import { PaymentType } from '@/enums/PaymentType';
import { CreateOrderRequest } from '@/contracts/CreateOrderRequest';
import { TransactionType } from '@/enums/TransactionType';
import Order from '@/Interfaces/Order';

export default class VoidService {
  createVoidOrder(orderToVoid: Order) {
    const order = new CreateOrderRequest();
    order.paymentType = orderToVoid.paymentType;
    order.transactionType = TransactionType.Void;
    order.customer = orderToVoid.customer;
    order.location = orderToVoid.location;
    order.relatedOrder = orderToVoid.id;
    if (orderToVoid.discounts && orderToVoid.discounts.length > 0) {
      orderToVoid.discounts[0].id = undefined;
      order.discounts = orderToVoid.discounts;
    }
    order.variantSnapshots = orderToVoid.variantSnapshots;
    return order;
  }
}
